<template>
  <div>
    <AppBar title="Start Page" :user="user"/>
    <br>
    <br>
    <br>
    <div align="center">
    <h1>Hi {{user.displayName}}, Welcome to LeftBrain Apps.</h1>
    </div>
  </div>
</template>

<script>
import AppBar from "../components/AppBar.vue";

export default {
  props: ["user"],
  data() {
    return {
      switch: true
    };
  },
  components: {
    AppBar
  }
};
</script>
